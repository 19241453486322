body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  min-height: 100%;
  font-family: "Work Sans", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

/* Global styles */

.container-fluid {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 700;
  font-size: 40px;
}

h2 {
  font-weight: 500;
  font-size: 36px;
}

p {
  font-weight: 400;
}

.card {
  height: 350px;
}

.navbar-light .navbar-toggler,
span.navbar-toggler-icon {
  border-color: white;
}

/* Header styles */

header,
footer {
  background-color: #9bce1a;
}

header h1 {
  color: #9bce1a;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:visited,
.navbar-light .navbar-nav .nav-link:active {
  color: white;
}

h1.border-title.aligncenter {
  text-align: center;
  margin-bottom: 50px;
  position: relative;
  color: #282828;
  font-weight: 200;
}

.contact-info-container a {
  text-decoration: none;
}

.contact-info {
  margin: 20px 0;
  background: rgb(81, 81, 168);
  width: 300px;
  padding: 10px;
  border-radius: 20px;
}

.contact-info span {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.contact-info svg {
  display: flex;
}

.contact-info:hover {
  border: 2px solid blue;
  cursor: pointer;
}

h1.border-title.aligncenter::before {
  background: url(/public/images/heading-bottom.png) no-repeat;
  content: "";
  height: 35px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  bottom: -40px;
  width: 206px;
  z-index: 9;
}

.navigation-bar {
  display: flex;
  justify-content: space-between;
}

header.app-header {
  width: 100%;
  height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgimage {
  width: 100%;
  height: 500px;
  background: url("/public/images/salonmaxxlanding.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bgimage h5 {
  color: white;
  text-shadow: 2px 2px #333;
}

#unique {
  color: #be3127;
}

.socialMedia {
  display: flex;
  align-items: center;
}

.socialMedia a {
  margin: 10px;
}

i.fab {
  color: #fff;
}

i.fab:hover {
  color: #212529;
}

.call-to-action-container {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: space-between;
}

.call-to-action-image {
  display: flex;
  justify-content: center;
}

.call-to-action-image img {
  width: 500px;
}

.menu-items li {
  cursor: pointer;
  color: #fff;
  display: inline-block;
  padding: 40px 20px 0 20px;
  font-size: 30px;
  font-weight: bold;
}

.menu-items li:hover {
  color: #be3127;
}

/* LOCATIONS STYLES */

.locationsContainer #pushTop {
  margin-top: 100px;
}

#locationHeader {
  background: #36567e;
  color: #fff;
  padding: 20px 0;
}

/* .locationsContainer .riolinda {
  background: url("./components/images/laundryco.jpg");
}

.locationsContainer .rancho {
  background: url("./components/images/superwashndrycover.jpg");
} */

.locationsContainer .overlay {
  background: #000;
  opacity: 0.7;
  position: relative;
  height: 492px;
  z-index: 25;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.locationsContainer .overlay .content {
  display: flex;
  align-items: center;
  color: #fff;
}

.locationsContainer .overlay:hover {
  background: none;
}

.locationsContainer .riolinda,
.locationsContainer .rancho {
  border: 4px solid #36567e;
  background-size: cover;
  height: 500px;
  padding: 0;
  margin: 20px;
}

/* Footer styles */

footer p {
  color: #fff;
}

/* Media Queries */

@media screen and (max-width: 1100px) {
  .call-to-action-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 40px 0;
  }

  .call-to-action-image {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .navigation-bar {
    display: block;
    text-align: center;
  }
}
